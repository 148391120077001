var exports = {};

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

exports = _classCallCheck, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;